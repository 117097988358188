import React from "react"
import { Link } from "gatsby"

class mainmenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isMenuOpen:false
        }
    }

    toggleOpenMenu = () => {
        this.setState(prevState => ({
            isMenuOpen: !prevState.isMenuOpen
        }));
    }

    render() {
        const ListLink = props => (
            <li>
                <Link to={props.to}>{props.children}</Link>
            </li>
        )

        const menuToggle = this.state.isMenuOpen ? 'open' : '';
        if (typeof document !== `undefined`) {
            const bodyClass = this.state.isMenuOpen ? document.body.classList.add('isOpen') : document.body.classList.remove('isOpen'); // eslint-disable-line no-unused-vars
            const htmlClass = this.state.isMenuOpen ? document.getElementsByTagName('html')[0].classList.add('isOpen') : document.getElementsByTagName('html')[0].classList.remove('isOpen'); // eslint-disable-line no-unused-vars
        }

        return(
            <React.Fragment>
                <div id="nav-icon" className={menuToggle}>
                    <button className="burger" onClick={ this.toggleOpenMenu.bind(this) }>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                    <div className="burgerlabel">Menu</div>
                </div>
                <ul className={`${menuToggle} mainmenu`} style={{ listStyle: `none` }}>
                    <li><a href="/" onClick={ this.toggleOpenMenu.bind(this) }>Home</a></li>
                    <li><a href="https://store.steampowered.com/app/1330610" target="_blank" rel="noopener noreferrer">Store</a></li>
                    <ListLink to="/art">Game Art</ListLink>
                    {/* <ListLink to="/news">News</ListLink> */}
                    <li><a href="/#contact" onClick={ this.toggleOpenMenu.bind(this) }>Get In Touch</a></li>
                </ul>
            </React.Fragment>
        )
    }
}

export default mainmenu