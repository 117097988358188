import { useStaticQuery, graphql } from "gatsby"

export const useSiteMetadata = () => {
    const { site } = useStaticQuery(
        graphql`
            query MetadataQuery {
                site {
                    siteMetadata {
                        title
                    }
                }
            }
        `
    )
    return site.siteMetadata
}
