import Header from "../components/header"
import Footer from "../components/footer"
import { Helmet } from "react-helmet"
import React from "react"
import { useSiteMetadata } from "../hooks/use-site-metadata"
export default ({children}) => {
  const meta = useSiteMetadata();

  return (
    <div id="app">
        <Helmet title={`${meta.title} - Independant UK Games Studio`} titleTemplate={`%s - ${meta.title} - Independant UK Games Studio`}/>
        <div className="content-wrapper">
            <header style={{ maxWidth: "100%" }}>
                <div className="header make__grid">
                    <Header />
                </div>
            </header>
            <main className="body">
                { children }
            </main>
        </div>
        <footer>
            <Footer />
        </footer>
    </div>
  )
}