import React from "react"
import { Link } from "gatsby"
import MainMenu from "../components/mainmenu"
import Logo from "../images/logo_112x75.png"

export default props => 
<div>
    <Link className="logo" to="/" style={{ textShadow: `none`, backgroundImage: `none`, display: `inline-block` }}>
        <img src={ Logo } alt="Works In Theory" />
    </Link>
    <MainMenu />
</div>